<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>巡检管理</a-breadcrumb-item>
            <a-breadcrumb-item>任务管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div
          v-if="!visible"
          class="flow-d-row"
          style="float: right; align-items: center"
        >
          <a-button type="primary" @click="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['state'].value"
                  v-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 未开始 </a-select-option>
                  <a-select-option value="1"> 进行中 </a-select-option>
                  <a-select-option value="2"> 已结束 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['executor_type'].value"
                  v-else-if="i == '执行模式'"
                  placeholder="请选择执行模式"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 执行人 </a-select-option>
                  <a-select-option value="1"> 执行角色 </a-select-option>
                </a-select>
                <a-date-picker
                  v-model="params['patrol_date'].value"
                  @change="onchangetime"
                  style="width: 173.5px"
                  v-else-if="i == '任务日期'"
                />
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content style="background: #fff; padding: 24px; margin: 0">
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="tasklist"
            :rowClassName="rowClassName"
          >
            <span slot="executorType" slot-scope="text"
              >{{ text == 0 ? '指定执行人' : '指定执行角色' }}
            </span>
            <span slot="status" slot-scope="text">
              <a-badge
                :status="text | stateTypeFilter"
                :text="text | stateFilter"
              />
            </span>
            <span style="font-family: '黑体'" slot="code" slot-scope="text"
              >{{ text }}
            </span>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">查看</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content style="background: #fff; padding: 24px; margin: 0">
        <div class="title-top">基本信息</div>
        <a-descriptions style="padding-left: 150px" :column="2">
          <a-descriptions-item label="任务编号">
            {{ taskSitelist.code }}
          </a-descriptions-item>
          <a-descriptions-item label="任务名称"
            >{{ taskSitelist.name }}
          </a-descriptions-item>
          <a-descriptions-item label="计划名称">{{
            taskSitelist.patrolPlanName
          }}</a-descriptions-item>
          <a-descriptions-item label="路线名称">
            {{ taskSitelist.patrolLineName }}
          </a-descriptions-item>
          <a-descriptions-item label="任务日期">
            {{ taskSitelist.patrolDate }}
          </a-descriptions-item>
          <a-descriptions-item label="路线时长(分钟)">
            {{ taskSitelist.duration }}
          </a-descriptions-item>
          <a-descriptions-item label="任务开始时间">
            {{ taskSitelist.patrolTime }}
          </a-descriptions-item>
          <a-descriptions-item label="任务结束时间"> </a-descriptions-item>
          <a-descriptions-item label="实际开始时间"> </a-descriptions-item>
          <a-descriptions-item label="实际结束时间"> </a-descriptions-item>
          <a-descriptions-item label="执行模式">{{
            taskSitelist.executorType == 0 ? '指定执行人' : '指定执行角色'
          }}</a-descriptions-item>
          <a-descriptions-item label="执行人">
            {{ taskSitelist.executorName }}</a-descriptions-item
          >
        </a-descriptions>
        <div class="title-top">路线点位</div>
        <a-table
          :row-selection="rowSelection"
          rowKey="id"
          :pagination="false"
          :columns="column"
          :data-source="taskSitelist.taskSites"
          :rowClassName="rowClassName"
        >
          <span style="font-family: '黑体'" slot="code" slot-scope="text"
            >{{ text }}
          </span>
          <span slot="patrolTime" slot-scope="text">{{ text | time }} </span>

          <a slot="qr" slot-scope="text, rexord">
            <a-popover placement="left" trigger="click">
              <template slot="content">
                <span style="width: 90px; height: 90px">
                  <img
                    style="width: 90px; height: 90px"
                    :src="'sccn/patrol/site/qr/' + rexord.patrolSiteId"
                    alt=""
                  />
                </span>
              </template>
              <span> 点击查看二维码 </span>
            </a-popover>
          </a>
          <span slot="status" slot-scope="text"
            >{{ text == 0 ? '未检查' : text == 1 ? '正常' : '异常' }}
          </span>
        </a-table>
        <a-pagination
          v-model="eleparams.current"
          :page-size="eleparams.pageSize"
          show-quick-jumper
          :default-current="1"
          :total="totals"
          @change="onpagesizes"
        />
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import moment from 'moment'
const columns = [
  {
    title: '任务编号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '任务名称',
    dataIndex: 'name',
  },
  {
    title: '计划名称',
    dataIndex: 'patrolPlanName',
  },
  {
    title: '路线名称',
    dataIndex: 'patrolLineName',
  },
  {
    title: '任务日期',
    dataIndex: 'patrolDate',
  },
  {
    title: '开始时间',
    dataIndex: 'patrolTime',
  },
  {
    title: '执行模式',
    dataIndex: 'executorType',
    scopedSlots: { customRender: 'executorType' },
  },
  {
    title: '执行人',
    dataIndex: 'executorName',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const column = [
  {
    title: '点位编号',
    dataIndex: 'site.code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '点位名称',
    dataIndex: 'site.name',
  },
  {
    title: '楼栋',
    dataIndex: 'site.building.name',
  },
  {
    title: '楼层',
    dataIndex: 'site.floor.name',
  },
  {
    title: '安装位置',
    dataIndex: 'site.place',
  },
  {
    title: '巡检时间',
    dataIndex: 'patrolTime',
    scopedSlots: { customRender: 'patrolTime' },
  },
  {
    title: '二维码',
    dataIndex: 'site.qrCode',
    scopedSlots: { customRender: 'qr' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
]
const statusMap = {
  0: {
    status: 'warning',
    text: '未开始',
  },
  1: {
    status: 'processing',
    text: '进行中',
  },
  2: {
    status: 'success',
    text: '已结束',
  },
}
// import moment from "moment";
import http from '../../../http'
export default {
  data() {
    return {
      column,
      columns,
      visible: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 20 },
      title: '',
      textlist: [
        '任务编号',
        '任务名称',
        '计划名称',
        '路线名称',
        '任务日期',
        '执行模式',
        '状态',
      ],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      value: '',
      eleparams: {
        current: 1,
        pageSize: 10,
      },
      params: {
        current: 1,
        pageSize: 10,
        name: {
          value: '',
          op: '%like%',
        },
        code: {
          value: '',
          op: '%like%',
        },
        patrol_plan_name: {
          value: '',
          op: '%like%',
        },
        patrol_line_name: {
          value: '',
          op: '%like%',
        },
        state: {
          value: undefined,
          op: '=',
        },
        executor_type: {
          value: undefined,
          op: '=',
        },
        patrol_date: {
          value: undefined,
          op: '=',
        },
      },
      total: 1,
      totals: 1,
      selectedRowKey: [],

      Id: '',
      tasklist: [],
      taskSitelist: [],
      form: {
        id: '',
      },
      e: '',
      rules: {
        duration: [
          {
            required: true,
            message: '巡检路线时长不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '巡检路线名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    stateFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].text
      }
    },
    stateTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].status || ''
      }
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    onchangetime(e) {
      if (e == null) {
        this.params['patrol_date'].value = undefined
      } else {
        this.params['patrol_date'].value = moment(e).format('YYYY-MM-DD')
      }
    },
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['name'].value = values.任务名称
        this.params['code'].value = values.任务编号
        this.params['patrol_plan_name'].value = values.计划名称
        this.params['patrol_line_name'].value = values.路线名称
      })
      this.getpatroltask()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['state'].value = undefined
      this.params['executor_type'].value = undefined
      this.params['patrol_date'].value = undefined
    },
    toggle() {
      this.expand = !this.expand
    },

    onClose() {
      this.visible = false
      this.selectedRowKey = []
      this.selectedRows = []
      this.form = {
        id: '',
        projectId: '',
        name: '',
        code: undefined,
        duration: '',
        state: '',
        sites: [],
        siteIds: [],
      }
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.getpatrollinestate(e.id, this.form.state)
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.title = '编辑'
      this.visible = true
      this.getpatroltaskId(item.id)
    },
    onpagesize(e) {
      this.params.current = e
      this.getpatroltask()
    },
    onpagesizes(e) {
      this.eleparams.current = e
      this.getpatroltask()
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该任务么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deletepatroltask()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deletepatroltask() {
      try {
        const res = await http.deletepatroltask(this.selectedRowKeys)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    //查
    async getpatroltask() {
      try {
        const res = await http.getpatroltask(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.tasklist = data.tasks.records
          this.total = data.tasks.total
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getpatroltaskId(id) {
      try {
        const res = await http.getpatroltaskId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.taskSitelist = data.task
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onpagesizet(e) {
      this.sitparams.current = e
    },
    onpagesizetmo(e) {
      this.mositparams.current = e
      this.getpatroltaskId(this.form.id)
    },
    timer() {
      return setTimeout(() => {
        this.getpatroltask()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getpatroltask()
    let users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    // this.callback();
    // this.getsts();
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKey = selectedRowKeys
          //   console.log(this.selectedRowKey);
          console.log(selectedRows)
        },
      }
    },
  },
}
</script>
<style scoped>
span {
  display: inline-block;
}
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 600px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.ant-advanced-search-form .ant-form-item {
  height: 80px;
  margin-bottom: 15px;
}
.ant-form-item {
  height: 50px;
  margin-bottom: 15px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.check-box-ro {
  background: #f3f7ff;
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 10px;
  margin-bottom: 6px;
  display: inline-block;
  font-size: 14px;
  align-items: center;
}
::v-deep .ant-popover-inner-content {
  padding: 5;
}
::v-deep .ant-form-item-label {
  text-align: right;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
.ant-calendar-picker-input.ant-input {
  border-radius: 0;
}
.title-top {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
  border-left: 3px solid #1890ff;
  color: #000;
  padding-left: 10px;
}
</style>
